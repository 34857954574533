import React from "react";
import { Link } from "gatsby";
import renderHTML from "../../helpers/renderHTML";

const Series = ({ link, title, position, length, id }) => {
  return (
    <Link to={`${link}`} activeClassName="active" className="series__post series-post" id={id}>
      <span className="series">
        <span>
          <strong>{position}</strong>/{length}
        </span>
      </span>
      <header className="series-post__header">
        <h1 className="series-post__title" dangerouslySetInnerHTML={renderHTML(title)} />
      </header>
    </Link>
  );
};

export default Series;
