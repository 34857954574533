import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

function PostCode({ language, children }) {
  const [isCopied, setIsCopied] = React.useState(false);

  const copyToClipboard = str => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <div style={{ position: "relative", "box-shadow": "rgba(0, 0, 0, 0.25) 0px 5px 10px" }}>
      <button
        type="button"
        className="copy"
        onClick={() => {
          copyToClipboard(children);
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 3000);
        }}
      >
        {isCopied ? "🎉 Copied!" : "Copy"}
      </button>
      <SyntaxHighlighter style={atomDark} language={language}>
        {children}
      </SyntaxHighlighter>
    </div>
  );
}

export default PostCode;
