import React from "react";
import { graphql } from "gatsby";
import parse, { domToReact } from "html-react-parser";
import Helmet from "react-helmet";
import { Disqus, CommentCount } from "gatsby-plugin-disqus";
import BreadCrumb from "../modules/Breadcrumb/BreadCrumb";
import renderHTML from "../helpers/renderHTML";
import Layout from "../Layout/Layout";
import SearchContainer from "../modules/Search/SearchContainer";
import SEO from "../Layout/Seo";
import CategoriesContainer from "../modules/Blog/CategoriesContainer";
import PostCode from "../modules/Blog/PostCode";
import CTANewsletter from "../modules/CTA/CTANewsletter";
import BlogPostSmall from "../modules/Blog/post-small";
import Series from "../modules/Blog/Series";

function getCode(node) {
  if (node.children.length > 0 && node.children[0].name === "code") {
    return node.children[0].children;
  }
  return node.children;
}

function getLanguage(node) {
  return node.attribs.class || "javascript";
}

const PostTemplate = ({ location, ...props }) => {
  const post = props.data.wordpressPost;

  const items = [
    { to: "/", label: "Home" },
    { to: "/blog", label: "Blog" },
    { to: `${post.categories[0].path}`, label: `${post.categories[0].name}` },
    { to: `${location.pathname}`, label: `${post.title}` }
  ];

  const relatedPosts = props.data.allWordpressPost.edges.slice(1, 4);

  const disqusConfig = {
    url: `${`http://localhost:8000${location.pathname}`}`,
    identifier: post.id,
    title: post.title
  };

  const cleanExcerpt = post.excerpt.replace(/<\/?[^>]+(>|$)/g, "");

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "blogpostpage"
        }}
      />
      <SEO
        title={post.yoast.title ? post.yoast.title : post.title}
        keywords={post.yoast.metakeywords}
        description={post.yoast.metadesc ? post.yoast.metadesc : cleanExcerpt}
      />
      <div className="blog__menu post-single__menu">
        <div className="container">
          <div className="categories__menu">
            <div className="categories__left">
              <CategoriesContainer props={props.data.wordpressWpApiMenusMenusItems.items} />
            </div>
            <div className="categories__right">
              <div className="search">
                <SearchContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="post-single__main-wrapper">
        <section className="post-single">
          <div className="container">
            <section className="post__content">
              <BreadCrumb crumbs={items} />
              <div className="post__entry">
                <div className="post__box">
                  <div className="post__entry-thumbnail">
                    {post.featured_media !== null ? (
                      <img
                        className="img-responsive lazyload"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
                        data-src={post.featured_media.localFile.childImageSharp.original.src}
                        alt="thumbnail"
                      />
                    ) : null}
                    <header className="post__entry-header">
                      <div className="post__entry-header-top">
                        {post.series_part && post.seriesTags && (
                          <span className="post__series-num">
                            {post.series_part} / {post.seriesTags.length + 1}
                          </span>
                        )}
                      </div>
                      <h1
                        className="post__entry-title post__title"
                        dangerouslySetInnerHTML={renderHTML(post.title)}
                      />
                      {post.tags !== null && (
                        <div className="post__tags">
                          {post.tags !== null
                            ? post.tags.map(tag => <a href={`${tag.path}`}>#{tag.name}</a>)
                            : null}
                        </div>
                      )}
                    </header>
                  </div>
                  <div className="post__meta-header">
                    <div className="post__info-wrapper">
                      <div className="post__meta-date">{post.date},</div>
                      <div className="author-name author-name--small">
                        <span>{post.author.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="post__entry-content">
                    {parse(post.content.replace(/<\/noscript>/g, "</noscript></div>"), {
                      replace: node => {
                        if (node.name === "pre") {
                          return (
                            node.children.length > 0 && (
                              <div className="code-block">
                                <span className="code-block__dots" />
                                <PostCode language={getLanguage(node)}>
                                  {domToReact(getCode(node))}
                                </PostCode>
                              </div>
                            )
                          );
                        }
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
              <aside className="blog__sidebar post__sidebar">
                <div className="post__sidebar-wrapper">
                  <div className="single__related">
                    {relatedPosts.length > 0 ? (
                      <>
                        <header className="block__header text-center">
                          <h3 className="post__sidebar-heading">Look for more posts</h3>
                        </header>
                        {relatedPosts.map((item, i) => {
                          return (
                            <BlogPostSmall
                              id={item.node.id}
                              position={i}
                              key={item.node.id}
                              link={item.node.path}
                              excerpt={item.node.excerpt.replace(/(\s\[&hellip;])/g, "...")}
                              author={{ name: item.node.author.name }}
                              title={item.node.title}
                              img={
                                item.node.featured_media &&
                                item.node.featured_media.localFile.childImageSharp.original.src
                              }
                              tags={item.node.tags}
                              date="21 November 2020"
                              related="true"
                              category={
                                typeof item.node.categories !== "undefined"
                                  ? item.node.categories[0]
                                  : null
                              }
                              series={{
                                part: item.node.series_part,
                                tags: item.node.seriesTags
                              }}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </aside>
            </section>
            <div className="series__wrapper">
              <p className="series-wrapper__title">
                <strong>This article is a part of series:</strong>
              </p>
              {post.seriesTags &&
                post.seriesTags.map((series, i) => (
                  <Series
                    link={series.link}
                    title={series.name}
                    position={i}
                    length={series.count}
                    id={series.id}
                  />
                ))}
            </div>
          </div>
        </section>
        <CTANewsletter />
        <div className="post__comments">
          <CommentCount config={disqusConfig} placeholder="..." />
          <Disqus config={disqusConfig} />
        </div>
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query currentPostQuery($id: String!, $category: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      link
      categories {
        id
        name
        path
        slug
      }
      slug
      yoast {
        title
        metakeywords
        metadesc
      }
      author {
        description
        name
        link
        avatar_urls {
          wordpress_48
        }
      }
      date(formatString: "DD MMMM YYYY")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      tags {
        id
        name
        path
      }
      #      seriesTags {
      #        id
      #        name
      #        path
      #        count
      #      }
      #      series_part
    }
    wordpressWpApiMenusMenusItems(slug: { eq: "categories-menu" }) {
      items {
        title
        object_slug
        wordpress_children {
          title
          object_id
          object_slug
        }
        url
      }
    }
    allWordpressPost(filter: { categories: { elemMatch: { name: { eq: $category } } } }) {
      edges {
        node {
          id
          featured_media {
            id
            source_url
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          path
          excerpt
          author {
            description
            name
            link
            avatar_urls {
              wordpress_48
            }
          }
          title
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
